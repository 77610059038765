<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`${currentMake} galerij`" :intro="currentGallery.fullGalleryDescription">
        <LoadingIcon :isLoading="isLoading" />
        <v-container class="px-5">
            <template v-for="(yearEvent, index) in roadtripsPerYear" >
                <div :key="index">
                    <v-container>
                        <v-row>
                            <v-col class="col-12 display-1 pa-0">{{yearEvent.year}}</v-col>
                        </v-row>
                    </v-container>

                    <template v-for="(event, event_index) in yearEvent.events" >
                        <div class="" :key="event_index">
                           <v-container class="pa-0" >
                                <v-row no-gutters >
                                    <v-col class="col-12 trip-gallery-header py-0">{{event.tripName}} ({{formatDayMonth(getDateOnly(event.tripDate))}})</v-col>
                                    <v-col class="col-12 rating px-0 pt-0 mt-n1 mb-2 d-flex">
                                        <v-rating
                                            v-model="event.averageRating"
                                            background-color="#b5b5b5"
                                            color="yellow accent-4"
                                            half-increments
                                            dense
                                            readonly
                                            size="22"></v-rating>
                                        <div class="comments">{{event.averageRating}} <span class="pl-1">({{event.numberOfRatings}})</span></div>
                                    </v-col>
                                </v-row>
                                <ImageRedirect @imgClick="goToGalleryTrip(currentMake, event.tripName, formatDate(getDateOnly(event.tripDate)))" :imageSource="event.galleryImageURL" :imgClass="'galleryImg'" />
                            </v-container>
                        </div>
                    </template>
                </div>
            </template>
        </v-container>
    </BannerTemplate>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import ImageRedirect from '@/components/common/ImageRedirect';
import BannerTemplate from '@/components/common/BannerTemplate';
import FormatMixin from '@/mixins/format.mixin';
import RouteMixin from '@/mixins/route.mixin';
import MenuHelperMixin from '@/mixins/menu-helper.mixin';
import MetaTagsMixin from '@/mixins/metatags.mixin';

export default {
    name: 'MerkGalerij',
    components: 
    {
        ImageRedirect,
        BannerTemplate,
        LoadingIcon
    },
    mixins: [FormatMixin, RouteMixin, MenuHelperMixin, MetaTagsMixin],
    data: () => ({
        isLoading: false,
        currentMake: '',
        currentGallery: {
            makeSpecificGalleryInformation: []
        },
        roadtripsPerYear: [],
        bannerSource:  require('@/assets/album/gallery_reviews_cover.jpg'),
    }),
    computed: {
        ...mapState('menuHelper', {
            roadtripLabels: state => state.roadtripLabels,
        }),
    },
    metaInfo() {
        return {
        title: this.currentMake ? `| ${this.currentMake}` : ``,
        meta: [
            this.getKeywords(`Volvo, Porsche, Mix, galerij`),
            this.getMetaDescription(`Roadpursuit galerij - ${this.currentMake}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas('Roadpursuit galerij', `${this.currentMake}`, `${window.location.origin}${this.bannerSource}`, 'Roadpursuit galerij')
        ]
        };
    },
    beforeMount() {
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted() 
    {
        this.$store.commit('setFooterVisibility', true);
        this.currentMake = this.captalizeFirstLetter(this.$route.params.make);
        
        if(this.roadtripLabels.length == 0)
        {
            this.$store.dispatch('menuHelper/getRoadtripLabels')
            .then(() => {
                this.getGalleryFromMake();
            })
            .catch(() => { this.isLoading = false; });
        }
        else {
            this.getGalleryFromMake();
        }
        
    },
    methods: {
        captalizeFirstLetter(str)
        {
            return str[0].toUpperCase() + str.slice(1);
        },
        getGalleryFromMake()
        {
            
            const id = this.getRoadtripLabelId(this.currentMake);
            this.isLoading = true;
            this.$store.dispatch('galleryModule/getGalleriesFromMake', id)
                .then(data => {
                this.currentGallery = data;

                let self = this;
                let roadtrips = [];
                data.makeSpecificGalleryInformation.forEach(trip => {
                    let roadtrip = {...trip};
                    roadtrip.year = this.getYearOnly(trip.tripDate);
                    roadtrips.push(roadtrip);
                });

                const groupedByYear = [...new Set(roadtrips.map(x => x.year))]
                groupedByYear.forEach(year => {
                    let groupedRoadtripsByYear = roadtrips.filter(x => x.year == year);
                    self.roadtripsPerYear.push({
                        year: year,
                        events: groupedRoadtripsByYear
                    })
                });

                this.isLoading = false;
            })
            .catch(() => { this.isLoading = false; });
        },
    }
  }
</script>

<style>
.galleryImg
{
    height: 350px;
    margin-top: -10px;
    margin-bottom: 30px;
    cursor: pointer;
}

.xs .galleryImg
{
    height: 250px;
}

</style>

